import React from "react";
import { Link } from "react-router-dom";
import "./bottom.css";

function Bottom() {
  return (
    <div className="bottom">
      <p>All Rights Reserved 2024. GCD-Glorious Design Creations</p>
      <Link to="/contact" className="bottom-link">
        Contact
      </Link>
    </div>
  );
}

export default Bottom;
