import React from "react";
import offerImage1 from "./../Assets/Hero/Our offerings.jpg";
import offerImage2 from "./../Assets/Hero/What we do.jpg";
import Bottom from "./Bottom";
import "./Offerings.css";
function Offerings() {
  return (
    <div>
      <div className="offering_h1">
        <div>What We Offer</div>
      </div>
      <div className="about_sec_2">
        <div>
          <h2 className="about-h2">
            Indulge in tranquility with our superior leather products for
            businesses — an exquisite blend of quality and style for
            unparalleled satisfaction.
          </h2>
        </div>
        <div>
          <img src={offerImage1} />
        </div>
      </div>
      <div className="about_sec_2">
        <div>
          <img src={offerImage2} />
        </div>
        <div>
          <h2 className="about-h2">
            At GCD, we craft timeless leather elegance. Our high-quality bags
            seamlessly blend style and functionality, reflecting sophistication
            and durability in every detai
          </h2>
        </div>
      </div>
      <Bottom></Bottom>
    </div>
  );
}

export default Offerings;
