import React from "react";
import whatWeDo from "./../Assets/Hero/What we do.jpg";
import ourOfferings from "./../Assets/Hero/Our offerings.jpg";
import contactUs from "./../Assets/Hero/contact us.jpg";
import Bottom from "./Bottom";
// importing csss
import "./Home.css";
import ImageSlider from "./ImageSlider";
import Contact from "./Contact";

function Home() {
  return (
    <>
      <div>
        <ImageSlider />
      </div>
      <div className="home-content ">
        {/* inner 1 */}

        <div className="home-inner">
          <div className="home-inner2">
            <img
              src={whatWeDo}
              alt="what we do"
              style={{
                height: "300px",
                width: "350px",
                border: "2px solid olive",
                padding: "2px",
              }}
            ></img>
          </div>
          <div>
            <h2>What We Do</h2>
            <p>
              At GCD - Glorious Design Creations, we craft timeless leather
              elegance. Our high-quality bags seamlessly blend style and
              functionality, reflecting sophistication and durability in every
              detail.
            </p>
          </div>
        </div>
        {/* inner 2 */}

        <div className="home-inner olive">
          <div>
            <h2 style={{ color: "white" }}>What We Offer</h2>
            <p style={{ color: "white" }}>
              Indulge in tranquility with our superior leather products for
              businesses — an exquisite blend of quality and style for
              unparalleled satisfaction. We Offer not only peace of mind with
              timely Delivery but also a commitment to produce the best in class
              goods.
            </p>
          </div>
          <div className="home-inner2">
            <img
              src={ourOfferings}
              alt="ourOfferings"
              style={{
                height: "300px",
                width: "350px",
                border: "2px solid black",
                padding: "2px",
              }}
            ></img>
          </div>
        </div>
        {/* inner 3 */}

        <div className="home-inner">
          <div className="home-inner2">
            <img
              src={contactUs}
              alt="service"
              style={{
                height: "300px",
                width: "350px",
                border: "2px solid olive",
                padding: "2px",
              }}
            ></img>
          </div>

          <div
            style={{
              paddingRight: "30px",
            }}
          >
            {/* <h2>Contact Us</h2>
            <p>
              Reach out to us for inquiries, collaborations, or just to say
              hello!
            </p> */}
            <ul
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {/* <li className="bold">phone: +91 70104 02836</li>
              <li className="bold">email: </li>
              <li className="bold">whatsApp: </li>
              <li className="bold">address: </li> */}
              <li>
                <Contact />
              </li>
            </ul>
          </div>
        </div>
        <Bottom></Bottom>
      </div>
    </>
  );
}

export default Home;
