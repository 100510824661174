import React from "react";

function Contact() {
  return (
    <div
      style={{
        paddingRight: "30px",
      }}
    >
      <h2
        style={{
          textAlign: "center",
          color: "olive",
        }}
      >
        Contact Us
      </h2>
      <p
        style={{
          textAlign: "center",
          color: "olive",
        }}
      >
        Reach out to us for inquiries, collaborations, purchasing finished
        leather goods like bags and wallets or just to say hello!
      </p>
      <ul
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <li className="bold">phone: +91 70104 02836</li>
        <li className="bold">email: gloriousdesignscreation@gmail.com </li>
        <li className="bold">whatsApp: 70104 02836 </li>
        <li className="bold">
          address: 1/2 Bamman para lane, Ballygunge Kolkata - 700 019
        </li>
      </ul>
    </div>
  );
}

export default Contact;
