import React from "react";
import aboutImage from "./../Assets/Hero/Hero 5.jpg";
import aboutImage2 from "./../Assets/Hero/Hero 4.jpg";
import aboutImage3 from "./../Assets/Hero/Hero 3.jpg";
import Bottom from "./Bottom";

import "./About.css";
function About() {
  return (
    <div>
      <div class="image-container">
        <img src={aboutImage} alt="Your Image" />
        <div class="text-overlay">About Us</div>
      </div>
      <h2 className="description_about">
        Welcome to Glorious Design, where passion transforms into style. Born
        from boundless imagination, our atelier crafts timeless pieces that make
        a statement. More than a brand, we're an ode to individuality, setting
        trends with every stitch. Join us in celebrating artistry, where
        elegance meets destiny in each masterpiece. Your signature style begins
        here
      </h2>
      <div className="about_sec_2">
        <div>
          <h2 className="about-h2">
            We are a Leather Goods Manufacturing Business striving to fullfill
            and nurture an everlasting happy relationship with Our Clients
          </h2>
        </div>
        <div>
          <img src={aboutImage2} />
        </div>
      </div>
      <div className="about_sec_2">
        <div>
          <img src={aboutImage3} />
        </div>
        <div>
          <h2 className="about-h2">
            Crafting elegance: Explore our handbags and leather goods, where
            each piece is a fusion of style and functionality.
          </h2>
        </div>
      </div>
      <Bottom></Bottom>
    </div>
  );
}

export default About;
