import "./NavBar.css";
import { FaBars, FaTimes } from "react-icons/fa";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import logo from "./../Assets/Hero/gcd.png";

function Navbar() {
  const navRef = useRef();

  const showNavBar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <header>
      <div className="logo-part">
        <img
          src={logo}
          alt="this is the logo of glorious design creations"
        ></img>
        <h3>Glorious Designs Creation</h3>
      </div>
      <nav ref={navRef}>
        <Link onClick={showNavBar} to="/">
          Home
        </Link>
        <Link onClick={showNavBar} to="/about">
          About
        </Link>
        <Link onClick={showNavBar} to="/offerings">
          What We Offer
        </Link>
        <Link onClick={showNavBar} to="/contact" className="contact">
          Contact Us
        </Link>
        <button onClick={showNavBar} className="nav-btn nav-close-btn">
          <FaTimes />
        </button>
      </nav>
      <button onClick={showNavBar} className="nav-btn">
        <FaBars />
      </button>
    </header>
  );
}

export default Navbar;
