import React, { useState, useEffect } from "react";
import img1 from "./../Assets/img1.jpg";
import img2 from "./../Assets/img2.jpg";
import img3 from "./../Assets/img3.jpg";
import img4 from "./../Assets/img4.jpg";
import img5 from "./../Assets/img5.jpg";
import "./ImageSlider.css";
function ImageSlider() {
  const images = [
    { src: img1 },
    { src: img2 },
    { src: img3 },
    { src: img4 },
    { src: img5 },
  ];

  const [img, setImg] = useState(0);

  const handlePrev = () => {
    setImg((prevImg) => (prevImg - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setImg((prevImg) => (prevImg + 1) % images.length);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImg((prevImg) => (prevImg + 1) % images.length);
    }, 8000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="img-slider-container">
      <button className="previous-btn" onClick={handlePrev}></button>
      <img src={images[img].src} alt="slider images" />
      <button className="next-btn" onClick={handleNext}></button>
    </div>
  );
}

export default ImageSlider;
